export default class Tools
{
    
    static toArray<T>(data:any,cls: { new(data:any): T})
    {
        var arr=[]
        for(var a of data)
        {
            arr.push(new cls(a))
        }
        return arr;
    }
    static toMapArray<T>(data:any,cls: { new(data:any): T}):any[]
    {
        var arr:any[]=[];
        for(var a in data??{})
        {
            arr.push([a,new cls(data[a])]);
        }
        return arr;
    }
    static toJsonMap(data:any)
    {
        var jsonObject:any={};
        data?.forEach((value:any, key:string) => {  
            jsonObject[key] = value  
        });  
        return jsonObject;
    }
    static getTime()
    {
        return new Date().getTime()
    }
    static getTimer(time:number)
    {
        time=Math.floor(time/1000)
        let sec=time%60
        time=Math.floor(time/60)
        let min=time%60
        time=Math.floor(time/60)
        return time+':'+min+':'+sec
    }
    static getTimerStr(time:number)
    {
        console.log('............');
        
        time=Math.floor(time/1000)
        let sec=time%60
        time=Math.floor(time/60)
        let min=time%60
        time = Math.floor(time / 60)
        let str = ''
        if(time>0)
            str += time+ ' ساعت '
        if(time>0 && min>0) str+= ' و ' 
        if (min > 0)
        {
            str +=min+ ' دقیقه '
        }
        return   str
    }
    static getRandom(max:number)
    {
        return Math.floor(Math.random()*max) 
    }
}