import Tools from "../Tools";

export enum LessonType
{
    Video=1
}
export class SubLessonModel
{
    title: string='';
    time: number=0;
    type: LessonType=LessonType.Video;
    address: string='';
    constructor(data?: { 
        title: string;
        time: number;
        type: LessonType;
        address:string
    })
    {  
        if (data) Object.assign(this, data);  
    }
}
export class Lesson
{
    title: string='';
    list:SubLessonModel[]=[]
    constructor(data?: { 
        title: string;
        list:SubLessonModel[]
    })
    {  
        if (data) Object.assign(this, data);  
    }
}
export default class CourseModel
{
    _id: number=0;
    title: string='';
    time: number=0;
    publishTime: string;
    link: string;
    lessonsCount: number=0;
    lessons: Lesson[]=[];
    instructor : string='';
    instructorId: number=0;
    categoryId: number=0;
    category: string='';
    description: string='';
    tags: string[] = [];
    level: string='';
    lang: string='';
    status: number=0;
    image: string='';
    clip: string='';
    rate: number=0;
    rateCount: number = 0;
    strTime: string;
    constructor(data?: { 
        _id: number;
        title: string;
        time: number;
        publishTime: string;
        link: string;
        lessonsCount: number;
        lessons: Lesson[];
        instructor : string;
        instructorId: number;
        categoryId: number;
        category: string;
        description: string;
        tags: string[]  ;
        level: string;
        lang: string;
        status: number;
        image: string;
        clip: string;
        rate: number;
        rateCount: number;
    })
    {  
        if (data) Object.assign(this, data);  
        if (this.time)
        {
            this.strTime= Tools.getTimerStr(this.time)
        }
    }
}