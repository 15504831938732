
<template>
 <main class="bg-transparent" v-if="showCourses">
       

      <!-- testimonial section -->
      <section class="py-100px" style="direction: rtl;">
        <div class="container">
          <!-- about section  -->
          <div class="grid grid-cols-1 lg:grid-cols-12 pt-30px gap-x-30px">
            <!-- about left -->
            <div
              class="lg:col-start-1 lg:col-span-4 relative z-0 mb-30px lg:mb-0 pb-0 md:pb-30px xl:pb-0 overflow-visible"
              data-aos="fade-up"
            >
              <div class="tilt">
                <img
                   :src="'https://storage.namag.ir/instructor/'+instructor._id +'.jpg' "
                  alt=""
                  class="w-full"
                >

                <img
                  class="absolute top-0 left-[-30px] animate-move-hor z-[-1]"
                  src="/assets/images/about/about_4.png"
                  alt=""
                >
              </div>
            </div>
            <!-- about right -->
            <div data-aos="fade-up" class="lg:col-start-5 lg:col-span-8">
              <div
                class="flex justify-between items-center flex-wrap md:flex-nowrap"
              >
                <div>
                  <h3
                    class="text-size-25 md:text-size-40 lg:text-3xl 2xl:text-size-40 leading-34px md:leading-13.5 lg:leading-11 2xl:leading-13.5 font-bold text-blackColor dark:text-blackColor-dark"
                  >
                    {{ instructor.name }}
                  </h3>
                  <p
                    class="text-sm md:text-base leading-7 text-contentColor dark:text-contentColor-dark"
                  >
                    {{instructor.title}}
                  </p>
                </div>
                <div v-if="false">
                  <p class="text-blackColor dark:text-blackColor-dark">
                    Review:
                  </p>
                  <div>
                    <i class="icofont-star text-size-15 text-yellow"></i>
                    <i class="icofont-star text-size-15 text-yellow"></i>
                    <i class="icofont-star text-size-15 text-yellow"></i>
                    <i class="icofont-star text-size-15 text-yellow"></i>
                    <i class="icofont-star text-size-15 text-yellow"></i>
                    <span class="text-xs text-lightGrey6">(44)</span>
                  </div>
                </div>
                <div>
                  <p class="text-blackColor dark:text-blackColor-dark">
                    دنبال کنید:
                  </p>
                  <ul
                    class="flex gap-13px text-base text-contentColor dark:text-contentColor-dark"
                  >
                    <li v-if="instructor.linkedin">
                      <a class="hover:text-primaryColor" :href="instructor.linkedin"
                        ><i class="icofont-linkedin"></i
                      ></a>
                    </li>
                    <li v-if="instructor.website">
                      <a class="hover:text-primaryColor" :href="instructor.website"
                        ><i class="icofont-web"></i
                      ></a>
                    </li>
                    <li v-if="instructor.insta" >
                      <a class="hover:text-primaryColor" :href="instructor.insta"
                        ><i class="icofont-instagram"></i
                      ></a>
                    </li>
                    <li v-if="instructor.youtube">
                      <a class="hover:text-primaryColor" :href="instructor.youtube"
                        ><i class="icofont-youtube-play"></i
                      ></a>
                    </li>
                  </ul>
                </div>
                <div>
                  <a v-if="false"
                    href="#"
                    class="text-sm md:text-size-15 text-whiteColor bg-primaryColor border border-primaryColor px-10 py-10px hover:text-primaryColor hover:bg-whiteColor rounded inline-block dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor"
                  > 
                    Follow
                  </a>
                </div>
              </div>

              <div
                class="pt-7 mt-30px border-t border-borderColor dark:border-borderColor-dark"
              >
                <h4
                  class="text-xl text-blackColor dark:text-blackColor-dark font-semibold mb-1"
                >
                  درباره مدرس :
                </h4>
                <p
                  class="leading-7 text-contentColor dark:text-contentColor-dark"
                >
                  {{ instructor.bio }}
                </p>
              </div>

              <!-- other courses -->
              <div class="mt-10" data-aos="fade-up">
                <!-- other courses heading -->
                <div class="mb-10px">
                  <h4
                    class="text-3xl font-bold text-blackColor dark:text-blackColor-dark leading-1.2"
                  >
                    دوره های مدرس
                  </h4>
                </div>
                <div data-aos="fade-up" class="sm:-mx-15px">
                  <!-- Swiper -->
                  <div class="swiper other-courses">
                    <div class="swiper-wrapper">
                      <!-- card 1 --> 
                       <CourseCard1 v-for="(course,index) in courses" :key="index" :course="course" :isLargeImage="true"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
   
</template>

<script lang="ts">
import CourseModel from '@/api/models/CourseModel';
import Instructor from '@/api/models/Instructor';
import PublicService from '@/api/services/PublicService';
import { Component, Vue, Watch } from 'vue-property-decorator';
import CourseCard1 from './HomeComponents/CourseCrad1.vue';

@Component({
    components: {
        CourseCard1
    },
})
export default class InstructorPage extends Vue {
    
    showCourses: boolean = false;
    courses: CourseModel[] = [];
    instructor: Instructor = new Instructor();
    mounted()
    {
        setTimeout(() => {
            this.init() 
        }, 100)
    }
    async init()
    {
        let id = parseInt(this.$route.params['id']);
        this.courses = await PublicService.getCourseByInstructor(id) 
        this.instructor = await PublicService.getInstructor(id)
        this.showCourses=true;
        
    } 
    @Watch('$route')
    reatechanged(data:any)
    {  
        try { 
            this.init()
        } catch (exp) { } 
 
    }
}
</script>