<template>
    <div class="text-start md:text-end">
        <span class="text-xs text-lightGrey6">({{ count }})</span> 
        <i :class="'icofont-star text-size-15 ' + (rate>=1 ? 'text-yellow':'')"></i>
        <i :class="'icofont-star text-size-15 ' + (rate>=2 ? 'text-yellow':'')"></i>
        <i :class="'icofont-star text-size-15 ' + (rate>=3 ? 'text-yellow':'')"></i>
        <i :class="'icofont-star text-size-15 ' + (rate>=4 ? 'text-yellow':'')"></i>
        <i :class="'icofont-star text-size-15 ' + (rate>=5 ? 'text-yellow':'')"></i>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class Star extends Vue {

    @Prop()
    count: number = 0; 
    @Prop()
    rate: number = 0; 
}
</script>