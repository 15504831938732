import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CourseDetail from '@/views/CourseDetail.vue' 
import Lesson from '@/views/Lesson.vue'
import Error404 from '@/views/Error404.vue'
import CoursesList from '@/views/CoursesList.vue' 
import InstructorPage from '@/views/Instructor.vue'
Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/course/:id',
    name: 'course',
    component: CourseDetail
  },
  {
    path: '/lesson/:id/:lesson/:sub',
    name: 'lesson',
    component: Lesson
  }, 
  {
    path: '/courseslist/:id',
    name: 'courseslist',
    component: CoursesList
  },
  {
    path: '/instructor/:id',
    name: 'instructor',
    component: InstructorPage
  },
  {
    path: '*',
    name: 'error',
    component: Error404
  }, 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
