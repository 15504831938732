export default class Instructor
{
    _id:number;
    name:string;
    title:string;
    bio:string;
    website:string;
    linkedin:string;
    youtube:string;
    insta:string;
    constructor(data?: {
        _id:number;
        name:string;
        title:string;
        bio:string;
        website:string;
        linkedin:string;
        youtube:string;
        insta:string;
    })
    {  
        if (data) 
        {
            Object.assign(this, data); 
        }
    }
}