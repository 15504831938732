<template>
  <main class="bg-transparent">
    <!-- banner section -->
    <section>
      <!-- banner section -->
      <div style="direction: rtl;"
        class="bg-lightGrey11 dark:bg-lightGrey11-dark relative z-0 overflow-hidden py-50px md:py-100px lg:pt-100px lg:pb-150px 2xl:pt-155px 2xl:pb-250px">
        <!-- animated icons -->
        <div>
          <img
            class="absolute left-10 bottom-[233px] md:left-[248px] md:bottom-[143px] lg:left-10 lg:bottom-[112px] 3xl:bottom-[233px] animate-move-var opacity-35 z-10"
            src="/assets/images/herobanner/herobanner__1.png" alt=""><img
            class="absolute left-0 top-0 md:left-[50px] md:top-[110px] lg:left-[30px] lg:top-[75px] 2xl:left-[50px] 2xl:top-16 3xl:top-[110px] animate-spin-slow"
            src="/assets/images/herobanner/herobanner__2.png" alt=""><img
            class="absolute md:left-[210px] md:top-[50px] animate-move-var2 hidden md:block"
            src="/assets/images/herobanner/herobanner__3.png" alt="">
          <img
            class="absolute top-20 left-[872px] md:left-[872px] lg:left-[595px] 2xl:left-[872px] hidden md:block animate-move-hor"
            src="/assets/images/herobanner/herobanner__4.png" alt="">
          <img
            class="absolute top-0 right-0 md:right-[110px] md:top-[100px] lg:right-[13px] lg:top[90px] 2xl:right-[82px] 2xl:top-[100px] 3xl:right-[110px] animate-move-hor"
            src="/assets/images/herobanner/herobanner__5.png" alt="">
        </div>
        <div class="container 2xl:container-secondary-md relative overflow-hidden">
          <div class="grid grid-cols-1 lg:grid-cols-2 items-center" style="direction: rtl;">
            <!-- banner Left -->
            <div data-aos="fade-up">
              <h3 class="  text-secondaryColor text-size-15 mb-5px md:mb-15px font-inter tracking-[2px] font-semibold">
                آموزش رایگان برای هر ایرانی
              </h3>
              <h1
                class="text-3xl text-blackColor dark:text-blackColor-dark md:text-6xl lg:text-size-50 2xl:text-6xl leading-10 md:leading-18 lg:leading-62px 2xl:leading-18 md:tracking-half lg:tracking-normal 2xl:tracking-half font-bold mb-15px">
                محتواهای چند رسانه ای <br class="hidden md:block">
                +100 دوره آموزشی
              </h1>
              <p class="text-size-15md:text-lg text-blackColor dark:text-blackColor-dark font-medium mb-45px">
                تو سایت یادبگیر می تونی هر چیزی رو که علاقه داری <br>
                به صورت کاملا رایگان یادبگیری .
              </p>

              <div>
                <a href="#category"
                  class="text-sm md:text-size-15 font-semibold text-whiteColor bg-primaryColor border border-primaryColor px-5 md:px-30px py-3 md:py-4 hover:text-primaryColor hover:bg-whiteColor rounded inline-block mr-6px md:mr-30px dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor">
                  دوره ها
                </a>
                <!-- <a href="#"
                  class="text-sm md:text-size-15 font-semibold text-whiteColor bg-secondaryColor border border-secondaryColor px-5 md:px-30px py-3 md:py-4 hover:text-secondaryColor hover:bg-whiteColor rounded inline-block mr-6px md:mr-30px dark:hover:bg-whiteColor-dark dark:hover:text-secondaryColor">
                  Explore More
                </a> -->
              </div>
            </div>
            <!-- banner right -->
            <div data-aos="fade-up">
              <div class="tilt">
                <img class="w-full" src="/assets/images/about/about_10.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--  brand section -->
    <div data-aos="fade-up">
      <div style="background-color: darkgray; direction: rtl;"
        class="container2-md flex flex-wrap items-center justify-center bg-white dark:bg-whiteColor-dark rounded-md mx-auto md:-translate-y-1/2 w-full shadow-brand">
        <div class="basis-1/2 md:basis-1/4 lg:basis-1/5 flex justify-center py-5 lg:py-35px 2xl:py-45px">
          <a href="https://origamicore.com/"><img src="/assets/images/brand/origami.svg" style="width:150px" alt=""></a>
        </div>
        <div class="basis-1/2 md:basis-1/4 lg:basis-1/5 flex justify-center py-5 lg:py-35px 2xl:py-45px">
          <a href="#">
          </a>
        </div>
        <div class="basis-1/2 md:basis-1/4 lg:basis-1/5 flex justify-center py-5 lg:py-35px 2xl:py-45px">
          <a href="#">
          </a>
        </div>
        <div class="basis-1/2 md:basis-1/4 lg:basis-1/5 flex justify-center py-5 lg:py-35px 2xl:py-45px">
          <a href="#">
          </a>
        </div>
        <div class="basis-1/2 md:basis-1/4 lg:basis-1/5 flex justify-center py-5 lg:py-35px 2xl:py-45px">
          <a href="#">
          </a>
        </div>
      </div>
    </div>

    <!-- Popular Subject Sectoion-->
    <section class="py-50px pb-20" id="category">
      <div class="container" style="direction: rtl;">
        <!--Subject Header  -->
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-30px mb-65px">
          <div class="lg:col-star-1 lg:col-span-4">
            <span
              class="text-sm font-semibold text-primaryColor bg-whitegrey3 px-6 py-5px mb-5 rounded-full inline-block"
              data-aos="fade-up">
              لیست دسته بندی ها
            </span>
            <h3
              class="text-3xl md:text-size-35 2xl:text-size-38 3xl:text-size-42 leading-10 md:leading-45px 2xl:leading-50px 3xl:leading-2xl font-bold text-blackColor dark:text-blackColor-dark"
              data-aos="fade-up">

              <span
                class="relative after:w-full after:h-[7px] after:bg-secondaryColor after:absolute after:left-0 after:bottom-3 md:after:bottom-4 after:z-[-1]">پر
                طرفدار</span>
            </h3>
          </div>

          <div class="lg:col-star-5 lg:col-span-5">
            <p class="text-sm md:text-base text-contentColor dark:text-contentColor-dark mb-10px 2xl:mb-50px 2xl:pl-50px"
              data-aos="fade-up">

            </p>
          </div>
          <div class="lg:col-star-10 lg:col-span-3 flex lg:justify-end" data-aos="fade-up">
            <div>
              <a v-if="false"
                class="text-size-15 text-whiteColor bg-secondaryColor px-25px py-10px border border-secondaryColor hover:text-secondaryColor hover:bg-whiteColor inline-block rounded dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor"
                href="#">تمام دسته بندی ها <i class="icofont-long-arrow-left"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-30px">
          <!--  subject cards  -->

          <!-- card 1 -->
          <div data-aos="fade-up">
            <div
              class="p-30px group bg-whiteColor rounded-xl transition-all duration-300 hover:-translate-y-5px shadow-dropdown-secodary hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor">
              <!-- card svg -->

              <div class="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                <div class="relative w-20 h-[60px]">

                  <img src="/assets/images/icon/category/icons8-source-code-48.png" />

                </div>
                <div class="service__small__img w-7 h-[60px]">
                  <svg class="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                      stroke="#FFB31F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
              <!-- card content -->
              <div>
                <a href="#"
                  class="text-size-22 sm:text-size-17 md:text-size-22 lg:text-size-17 2xl:text-size-22 leading-29px sm:leading-15px md:leading-29px lg:leading-15px 2xl:leading-29px font-semibold mb-5px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor  dark:transition-all dark:duration-300">
                  برنامه نویسی
                </a>
                <p class="text-contentColor group-hover:text-whiteColor leading-29px">
                  موبایل ، وب و ...
                </p>
              </div>
            </div>
          </div>
          <!-- card 2 -->
          <div data-aos="fade-up">
            <div
              class="p-30px group bg-whiteColor rounded-xl transition-all duration-300 hover:-translate-y-5px shadow-dropdown-secodary hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor">
              <!-- card svg -->
              <div class="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                <div class="relative w-20 h-[60px]">

                  <img src="/assets/images/icon/category/icons8-language-48.png" />
                </div>
                <div class="service__small__img w-7 h-[60px]">
                  <svg class="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                      stroke="#FFB31F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
              <!-- card content -->
              <div>
                <a href="#"
                  class="text-size-22 sm:text-size-17 md:text-size-22 lg:text-size-17 2xl:text-size-22 leading-29px sm:leading-15px md:leading-29px lg:leading-15px 2xl:leading-29px font-semibold mb-5px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor  dark:transition-all dark:duration-300">
                  زبان های خارجی
                </a>
                <p class="text-contentColor group-hover:text-whiteColor leading-29px">
                  انگلیسی ، فرانسه ، آلمانی و...
                </p>
              </div>
            </div>
          </div>
          <!-- card 3 -->
          <div data-aos="fade-up">
            <div
              class="p-30px group bg-whiteColor rounded-xl transition-all duration-300 hover:-translate-y-5px shadow-dropdown-secodary hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor">
              <!-- card svg -->
              <div class="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                <div class="relative w-20 h-[60px]">

                  <img src="/assets/images/icon/category/icons8-backpack-48.png" />
                </div>
                <div class="service__small__img w-7 h-[60px]">
                  <svg class="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                      stroke="#FFB31F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
              <!-- card content -->
              <div>
                <a href="#"
                  class="text-size-22 sm:text-size-17 md:text-size-22 lg:text-size-17 2xl:text-size-22 leading-29px sm:leading-15px md:leading-29px lg:leading-15px 2xl:leading-29px font-semibold mb-5px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor  dark:transition-all dark:duration-300">
                  مدرسه
                </a>
                <p class="text-contentColor group-hover:text-whiteColor leading-29px">
                  ریاضی ، فیزیک و ...
                </p>
              </div>
            </div>
          </div>
          <!-- card 4 -->
          <div data-aos="fade-up">
            <div
              class="p-30px group bg-whiteColor rounded-xl transition-all duration-300 hover:-translate-y-5px shadow-dropdown-secodary hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor">
              <!-- card svg -->
              <div class="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                <div class="relative w-20 h-[60px]">

                  <img src="/assets/images/icon/category/icons8-graduation-cap-48.png" />
                </div>
                <div class="service__small__img w-7 h-[60px]">
                  <svg class="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                      stroke="#FFB31F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
              <!-- card content -->
              <div>
                <a href="#"
                  class="text-size-22 sm:text-size-17 md:text-size-22 lg:text-size-17 2xl:text-size-22 leading-29px sm:leading-15px md:leading-29px lg:leading-15px 2xl:leading-29px font-semibold mb-5px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor  dark:transition-all dark:duration-300">
                  دانشگاه
                </a>
                <p class="text-contentColor group-hover:text-whiteColor leading-29px">
                  مهندسی ، علوم پایه و ...
                </p>
              </div>
            </div>
          </div>
          <!-- card 5 -->
          <div data-aos="fade-up">
            <div
              class="p-30px group bg-whiteColor rounded-xl transition-all duration-300 hover:-translate-y-5px shadow-dropdown-secodary hover:bg-primaryColor hover:text-whiteColor dark:bg-whiteColor-dark dark:hover:bg-primaryColor">
              <!-- card svg -->
              <div class="-translate-y-2 flex justify-between overflow-hidden mb-10px">
                <div class="relative w-20 h-[60px]">

                  <img src="/assets/images/icon/category/icons8-cyber-security-48.png" />
                </div>
                <div class="service__small__img w-7 h-[60px]">
                  <svg class="icon__hover__img w-7 h-[60px] opacity-0 group-hover:opacity-100" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M16.5961 10.265L19 1.33069L10.0022 3.73285L1 6.1306L7.59393 12.6627L14.1879 19.1992L16.5961 10.265Z"
                      stroke="#FFB31F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </div>
              </div>
              <!-- card content -->
              <div>
                <a href="#"
                  class="text-size-22 sm:text-size-17 md:text-size-22 lg:text-size-17 2xl:text-size-22 leading-29px sm:leading-15px md:leading-29px lg:leading-15px 2xl:leading-29px font-semibold mb-5px hover:text-secondaryColor font-hind  dark:text-whiteColor dark:hover:text-secondaryColor  dark:transition-all dark:duration-300">
                  فناوری اطلاعات
                </a>
                <p class="text-contentColor group-hover:text-whiteColor leading-29px">
                  شبکه ، امنیت و ...
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>

    <!-- courses section -->
    <section>
      <div style="padding-bottom: 12.375rem;direction: rtl;" id="courses"
        class="pt-50px pb-10 md:pt-70px md:pb-50px lg:pt-20 2xl:pt-100px 2xl:pb-70px bg-whiteColor dark:bg-whiteColor-dark">
        <div class="filter-container container">
          <div class="flex gap-15px lg:gap-30px flex-wrap lg:flex-nowrap items-center">
            <!-- courses Left -->
            <div class="basis-full lg:basis-[500px]" data-aos="fade-up">
              <span
                class="text-sm font-semibold text-primaryColor bg-whitegrey3 px-6 py-5px mb-5 rounded-full inline-block">
                لیست دوره ها
              </span>
              <h3
                class="text-3xl md:text-[35px] lg:text-size-42 leading-[45px] 2xl:leading-[45px] md:leading-[50px] font-bold text-blackColor dark:text-blackColor-dark"
                data-aos="fade-up">
                دوره های پربازدید و جدید
              </h3>
            </div>
            <!-- courses right
            <div class="basis-full lg:basis-[700px]">
              <ul
                class="filter-controllers flex flex-wrap sm:flex-nowrap justify-start lg:justify-end button-group filters-button-group"
                data-aos="fade-up">
                <li>
                  <button data-filter="*"
                    class="is-checked dark:is-checked pr-5 md:pr-10 lg:pr-17px 2xl:pr-10 text-contentColor font-medium hover:text-primaryColor dark:text-contentColor-dark dark:hover:text-primaryColor">
                    See All
                  </button>
                </li>
                <li>
                  <button data-filter=".filter1"
                    class="pr-5 md:pr-10 lg:pr-17px 2xl:pr-10 text-contentColor font-medium hover:text-primaryColor dark:text-contentColor-dark dark:hover:text-primaryColor">
                    Data science
                  </button>
                </li>
                <li>
                  <button data-filter=".filter2"
                    class="pr-5 md:pr-10 lg:pr-17px 2xl:pr-10 text-contentColor font-medium hover:text-primaryColor dark:text-contentColor-dark dark:hover:text-primaryColor">
                    Engineering
                  </button>
                </li>
                <li>
                  <button data-filter=".filter3"
                    class="pr-5 md:pr-10 lg:pr-17px 2xl:pr-10 text-contentColor font-medium hover:text-primaryColor dark:text-contentColor-dark dark:hover:text-primaryColor">
                    Featured
                  </button>
                </li>
                <li>
                  <button data-filter=".filter4"
                    class="text-contentColor font-medium hover:text-primaryColor dark:text-contentColor-dark dark:hover:text-primaryColor">
                    Architecture
                  </button>
                </li>
              </ul>
            </div> -->
          </div>

          <!-- course cards -->

          <div v-if="showCourses" class="container p-0 filter-contents flex flex-wrap sm:-mx-15px mt-7 lg:mt-10"
            data-aos="fade-up">
            <!-- card 1 -->
            <CourseCard v-for="(course,courseIndex) in courses" :key="courseIndex" :course="course" />

          </div>
        </div>
      </div>
    </section>


    <!-- news and blogs section -->
    <section v-if="false">
      <div class="container py-10 md:py-50px lg:py-60px 2xl:py-100px">
        <!-- heading -->

        <div data-aos="fade-up" class="text-center mb-15px">
          <span class="text-size-15 font-semibold text-secondaryColor inline-block uppercase mb-[13px]">
            NEWS & BLOG
          </span>
          <h3
            class="text-3xl md:text-size-35 lg:text-size-45 leading-10 md:leading-2xl font-bold text-blackColor dark:text-blackColor-dark">
            Latest News & Blogs
          </h3>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-30px">
          <!-- blog 1 -->
          <div data-aos="fade-up" class="shadow-dropdown-secodary bg-transparent">
            <div class="relative group p-10px">
              <img src="/assets/images/blog/blog_5.png" alt="" class="w-full">
              <!-- social icons -->
              <div class="absolute left-[30px] bottom-[30px]">
                <a href="#"
                  class="px-12 py-10px text-size-15 leading-1 text-whiteColor bg-blackColor border border-blackColor hover:text-blackColor hover:bg-whiteColor rounded-full dark:text-whiteColor-dark dark:bg-blackColor-dark dark:hover:text-blackColor-dark dark:hover:bg-whiteColor-dark dark:border-blackColor-dark">Story</a>
              </div>
            </div>
            <div
              class="pt-30px pr-5 pb-10 pl-30px md:py-5 md:px-10 lg:pt-30px lg:pr-10px lg:pb-10 lg:pl-5 2xl:pt-30px 2xl:pr-5 2xl:pb-10 2xl:pl-30px">
              <p class="text-base text-primaryColor uppercase font-bold mb-15px">
                20 APRIL 2024
              </p>
              <h5>
                <a href="#"
                  class="text-2xl md:text-xl 2xl:text-2xl leading-8 md:leading-7 2xl:leading-8 font-bold text-blackColor dark:text-blackColor-dark hover:text-primaryColor dark:hover:text-primaryColor mb-15px">
                  Facebook design is dedicated to what's new in design
                </a>
              </h5>
              <a href="#"
                class="text-blackColor hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor">Read
                More <i class="icofont-long-arrow-right"></i></a>
            </div>
          </div>
          <!-- blog 2 -->
          <div data-aos="fade-up" class="shadow-dropdown-secodary bg-transparent">
            <div class="relative group p-10px">
              <img src="/assets/images/blog/blog_24.png" alt="" class="w-full">
              <!-- social icons -->
              <div class="absolute left-[30px] bottom-[30px]">
                <a href="#"
                  class="px-12 py-10px text-size-15 leading-1 text-whiteColor bg-blackColor border border-blackColor hover:text-blackColor hover:bg-whiteColor rounded-full dark:text-whiteColor-dark dark:bg-blackColor-dark dark:hover:text-blackColor-dark dark:hover:bg-whiteColor-dark dark:border-blackColor-dark">Story</a>
              </div>
            </div>
            <div
              class="pt-30px pr-5 pb-10 pl-30px md:py-5 md:px-10 lg:pt-30px lg:pr-10px lg:pb-10 lg:pl-5 2xl:pt-30px 2xl:pr-5 2xl:pb-10 2xl:pl-30px">
              <p class="text-base text-primaryColor uppercase font-bold mb-15px">
                20 APRIL 2024
              </p>
              <h5>
                <a href="#"
                  class="text-2xl md:text-xl 2xl:text-2xl leading-8 md:leading-7 2xl:leading-8 font-bold text-blackColor dark:text-blackColor-dark hover:text-primaryColor dark:hover:text-primaryColor mb-15px">
                  Facebook design is dedicated to what's new in design
                </a>
              </h5>
              <a href="#"
                class="text-blackColor hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor">Read
                More <i class="icofont-long-arrow-right"></i></a>
            </div>
          </div>
          <!-- blog 3 -->
          <div data-aos="fade-up" class="shadow-dropdown-secodary bg-transparent">
            <div class="relative group p-10px">
              <img src="/assets/images/blog/blog_25.png" alt="" class="w-full">
              <!-- social icons -->
              <div class="absolute left-[30px] bottom-[30px]">
                <a href="#"
                  class="px-12 py-10px text-size-15 leading-1 text-whiteColor bg-blackColor border border-blackColor hover:text-blackColor hover:bg-whiteColor rounded-full dark:text-whiteColor-dark dark:bg-blackColor-dark dark:hover:text-blackColor-dark dark:hover:bg-whiteColor-dark dark:border-blackColor-dark">Story</a>
              </div>
            </div>
            <div
              class="pt-30px pr-5 pb-10 pl-30px md:py-5 md:px-10 lg:pt-30px lg:pr-10px lg:pb-10 lg:pl-5 2xl:pt-30px 2xl:pr-5 2xl:pb-10 2xl:pl-30px">
              <p class="text-base text-primaryColor uppercase font-bold mb-15px">
                20 APRIL 2024
              </p>
              <h5>
                <a href="#"
                  class="text-2xl md:text-xl 2xl:text-2xl leading-8 md:leading-7 2xl:leading-8 font-bold text-blackColor dark:text-blackColor-dark hover:text-primaryColor dark:hover:text-primaryColor mb-15px">
                  Facebook design is dedicated to what's new in design
                </a>
              </h5>
              <a href="#"
                class="text-blackColor hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor">Read
                More <i class="icofont-long-arrow-right"></i></a>
            </div>
          </div>
        </div>

        <div class="flex justify-center" data-aos="fade-up">
          <a href="#"
            class="text-size-15 px-47px py-15px bg-primaryColor text-whiteColor border border-primaryColor hover:text-primaryColor hover:bg-whiteColor dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor mt-10 md:mt-50px rounded uppercase">More
            Blog</a>
        </div>
      </div>
    </section>
  </main>

</template>

<script lang="ts">
import CourseModel from '@/api/models/CourseModel';
import PublicService from '@/api/services/PublicService';
import { Component, Vue } from 'vue-property-decorator';
import CourseCard from './HomeComponents/CourseCrad.vue';

@Component({
  components: { 
    CourseCard
  },
})
export default class HomeView extends Vue {
  showCourses: boolean = false;
  courses: CourseModel[] = [];
  mounted()
  {
    setTimeout(() => {
      this.showCourses=true
    }, 100)
    this.init()
  }
  async init()
  {
    this.courses = await PublicService.getCourses()
  }
}
</script>
