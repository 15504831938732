import axios from 'axios' 
export default class BaseServices
{
  static async postSSE(url:string,data:any,event:(data:string)=>void)
  {
    console.log('?>');
    
    let resp=await fetch(url,{
      method:'POST',
      headers:{
        'Accept': 'application/json',
        'Content-Type':'application/json'
      },
      body:JSON.stringify(data) 
    })
    const reader=resp.body?.pipeThrough(new TextDecoderStream()).getReader();
    while(true)
    {
      if(!reader)break;
      const {value,done} =await reader .read()
      if(done)break;
      if(value)
        event(value)
    }
  }
	static async post(url:string,data:any,useAuth:boolean=true):Promise<any>
  {
		var path= url;
		var headers:any={};
		if(window.localStorage.xpltoken && useAuth){
			headers['authorization']=window.localStorage.xpltoken
		}
		return new Promise((res,rej)=>{
      axios.post(path , data , {headers})
        .then(function (response:any) {
          if(response.data.token)
          {
            window.localStorage.xpltoken=response.data.token
          }
          res(response.data.data ?? response.data.token) 
        })
        .catch(function (error:any) {
          console.log(error); 
          
        alert(error.response.data.message)
          rej(error.response.data)
        })

    })  
	}
	static async put(url:string,data:any,useAuth:boolean=true){
		var path= url;
		var headers:any={};
		if(window.localStorage.xpltoken && useAuth){
			headers['authorization']=window.localStorage.xpltoken
		}
		return await axios.put(path , data , {headers})
      .then(function (response:any) {
        if(response.data.token)
        {
          window.localStorage.xpltoken=response.data.token
        }
        return response.data.data ?? response.data.token
      })
      .catch(function (error:any) {
        console.log(error); 
        throw error.response.data
      })
	}
	static async delete(url:string,id:any,useAuth:boolean=true){
		var path= url;
		var headers:any={};
		if(window.localStorage.xpltoken && useAuth){
			headers['authorization']=window.localStorage.xpltoken
		}
		return await axios.delete(path+'/'+id ,  {headers})
      .then(function (response:any) {
        if(response.data.token)
        {
          window.localStorage.xpltoken=response.data.token
        }
        return response.data.data ?? response.data.token
      })
      .catch(function (error:any) {
        console.log(error); 
        throw error.response.data
      })
	}

	static async get(url:string){
		var headers:any={};
		if(window.localStorage.xpltoken){
			headers['authorization']=window.localStorage.xpltoken
		}
    return await axios.get(url , {headers})
      .then(function (response:any) {
        if(response.data.token)
        {
          window.localStorage.xpltoken=response.data.token
        }
        return response.data.data
      })
      .catch(function (error:any) {
        console.log(error);
        // if(error.response.status == 403){
        //   AccountService.logout()
        // }
        // toast.error("Server Error")
        throw error.response.data
      })
		// var dt = await axios.get(url,{headers} );
    // if(dt.data.token){
    //   localStorage.setItem('token',dt.data.token)
    // }
		// return dt.data.data;
	}
}
