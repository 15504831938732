




<template>
    <main class="bg-transparent">

        <!--course details section -->
        <section v-if="!loading">
            <div style="display: none;">
                {{ counter }}

            </div>
            <div style="    direction: rtl;" class="container py-10 md:py-50px lg:py-60px 2xl:py-100px">
                <div class="grid grid-cols-1 lg:grid-cols-12 gap-30px">
                    <div class="lg:col-start-1 lg:col-span-8 space-y-[35px]">
                        <!-- course 1 -->
                        <div data-aos="fade-up">
                            <!-- course thumbnail -->
                            <div class="overflow-hidden relative mb-5">
                                <img :src="course.image+'img.jpg'" alt="" class="w-full">
                            </div>
                            <!-- course content -->
                            <div>
                                <div class="flex items-center justify-between flex-wrap gap-6 mb-30px"
                                    data-aos="fade-up">
                                    <div class="flex items-center gap-6">
                                        <button v-for="(tag,tagIndex)  in course.tags" :key="tagIndex"
                                            class="text-sm text-whiteColor bg-primaryColor border border-primaryColor px-26px py-0.5 leading-23px font-semibold hover:text-primaryColor hover:bg-whiteColor rounded inline-block dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor">
                                            {{ tag }}
                                        </button>

                                    </div>
                                    <div>
                                        <p class="text-sm text-contentColor dark:text-contentColor-dark font-medium">
                                            آخرین بروزرسانی:
                                            <span class="text-blackColor dark:text-blackColor-dark">{{
                                                course.publishTime }}</span>
                                        </p>
                                    </div>
                                </div>

                                <!-- titile -->
                                <h4 class="text-size-32 md:text-4xl font-bold text-blackColor dark:text-blackColor-dark mb-15px leading-43px md:leading-14.5"
                                    data-aos="fade-up">
                                    {{course.title}}
                                </h4>
                                <!-- price and rating -->
                                <div class="flex gap-5 flex-wrap items-center mb-30px" data-aos="fade-up">
                                    <div class="text-size-21 font-medium text-primaryColor font-inter leading-25px">

                                        <span class="ml-6"> رایگان</span>
                                    </div>
                                    <div class="flex items-center">
                                        <div>
                                            <i class="icofont-book-alt pr-5px text-primaryColor text-lg"></i>
                                        </div>
                                        <div>
                                            <span class="text-sm text-black dark:text-blackColor-dark">{{
                                                course.lessonsCount }} درس</span>
                                        </div>
                                    </div>
                                    <div class="text-start md:text-end">
                                        <span class="text-xs text-lightGrey6">({{ course.rateCount }})</span>
                                        <i
                                            :class="'icofont-star text-size-15 ' + (course.rate>=1 ? 'text-yellow':'')"></i>
                                        <i
                                            :class="'icofont-star text-size-15 ' + (course.rate>=2 ? 'text-yellow':'')"></i>
                                        <i
                                            :class="'icofont-star text-size-15 ' + (course.rate>=3 ? 'text-yellow':'')"></i>
                                        <i
                                            :class="'icofont-star text-size-15 ' + (course.rate>=4 ? 'text-yellow':'')"></i>
                                        <i
                                            :class="'icofont-star text-size-15 ' + (course.rate>=5 ? 'text-yellow':'')"></i>

                                    </div>
                                </div>
                                <p class="text-sm md:text-lg text-contentColor dark:contentColor-dark mb-25px !leading-30px"
                                    data-aos="fade-up">
                                    {{ course.description }}
                                </p>
                                <!-- details -->
                                <div>
                                    <h4 class="text-size-22 text-blackColor dark:text-blackColor-dark font-bold pl-2 before:w-0.5 relative before:h-[21px] before:bg-primaryColor before:absolute before:bottom-[5px] before:left-0 leading-30px mb-25px"
                                        data-aos="fade-up">
                                        اطلاعات دوره
                                    </h4>

                                    <div class="bg-darkdeep3 dark:bg-darkdeep3-dark mb-30px grid grid-cols-1 md:grid-cols-2"
                                        data-aos="fade-up">
                                        <ul
                                            class="p-10px md:py-55px md:pl-50px md:pr-70px lg:py-35px lg:px-30px 2xl:py-55px 2xl:pl-50px 2xl:pr-70px border-r-2 border-borderColor dark:border-borderColor-dark space-y-[10px]">
                                            <li>
                                                <p
                                                    class="text-contentColor2 dark:text-contentColor2-dark flex justify-between items-center">
                                                    مدرس :
                                                    <span
                                                        class="text-base lg:text-sm 2xl:text-base text-blackColor dark:text-deepgreen-dark font-medium text-opacity-100">
                                                        {{ course.instructor }}
                                                    </span>
                                                </p>
                                            </li>
                                            <li v-if="false">
                                                <p
                                                    class="text-contentColor2 dark:text-contentColor2-dark flex justify-between items-center">
                                                    Lectures :
                                                    <span
                                                        class="text-base lg:text-sm 2xl:text-base text-blackColor dark:text-deepgreen-dark font-medium text-opacity-100">
                                                        120 sub</span>
                                                </p>
                                            </li>
                                            <li>
                                                <p
                                                    class="text-contentColor2 dark:text-contentColor2-dark flex justify-between items-center">
                                                    زمان دوره :
                                                    <span
                                                        class="text-base lg:text-sm 2xl:text-base text-blackColor dark:text-deepgreen-dark font-medium text-opacity-100">
                                                        {{ course.strTime }} </span>
                                                </p>
                                            </li>
                                            <li v-if="false">
                                                <p
                                                    class="text-contentColor2 dark:text-contentColor2-dark flex justify-between items-center">
                                                    Enrolled :
                                                    <span
                                                        class="text-base lg:text-sm 2xl:text-base text-blackColor dark:text-deepgreen-dark font-medium text-opacity-100">
                                                        2 students</span>
                                                </p>
                                            </li>
                                            <li v-if="false">
                                                <p
                                                    class="text-contentColor2 dark:text-contentColor2-dark flex justify-between items-center">
                                                    Total :
                                                    <span
                                                        class="text-base lg:text-sm 2xl:text-base text-blackColor dark:text-deepgreen-dark font-medium text-opacity-100">
                                                        222 students</span>
                                                </p>
                                            </li>
                                        </ul>
                                        <ul
                                            class="p-10px md:py-55px md:pl-50px md:pr-70px lg:py-35px lg:px-30px 2xl:py-55px 2xl:pl-50px 2xl:pr-70px border-r-2 border-borderColor dark:border-borderColor-dark space-y-[10px]">
                                            <li>
                                                <p
                                                    class="text-contentColor2 dark:text-contentColor2-dark flex justify-between items-center">
                                                    سطح دوره :
                                                    <span
                                                        class="text-base lg:text-sm 2xl:text-base text-blackColor dark:text-deepgreen-dark font-medium text-opacity-100">
                                                        {{course.level}} </span>
                                                </p>
                                            </li>
                                            <li>
                                                <p
                                                    class="text-contentColor2 dark:text-contentColor2-dark flex justify-between items-center">
                                                    زبان :
                                                    <span
                                                        class="text-base lg:text-sm 2xl:text-base text-blackColor dark:text-deepgreen-dark font-medium text-opacity-100">
                                                        {{ course.lang }} </span>
                                                </p>
                                            </li>
                                            <li v-if="false">
                                                <p
                                                    class="text-contentColor2 dark:text-contentColor2-dark flex justify-between items-center">
                                                    Price Discount :
                                                    <span
                                                        class="text-base lg:text-sm 2xl:text-base text-blackColor dark:text-deepgreen-dark font-medium text-opacity-100">
                                                        -20%</span>
                                                </p>
                                            </li>
                                            <li v-if="false">
                                                <p
                                                    class="text-contentColor2 dark:text-contentColor2-dark flex justify-between items-center">
                                                    Regular Price :
                                                    <span
                                                        class="text-base lg:text-sm 2xl:text-base text-blackColor dark:text-deepgreen-dark font-medium text-opacity-100">
                                                        $228/Mo</span>
                                                </p>
                                            </li>
                                            <li>
                                                <p
                                                    class="text-contentColor2 dark:text-contentColor2-dark flex justify-between items-center">
                                                    وضعیت دوره :
                                                    <span
                                                        class="text-base lg:text-sm 2xl:text-base text-blackColor dark:text-deepgreen-dark font-medium text-opacity-100">
                                                        {{course.status}}</span>
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- course tab -->
                                <div data-aos="fade-up" class="tab course-details-tab">
                                    <div class="tab-links flex flex-wrap md:flex-nowrap mb-30px rounded gap-0.5">
                                        <button
                                            class="is-checked relative p-10px md:px-25px md:py-15px lg:py-3 2xl:py-15px 2xl:px-45px text-blackColor bg-whiteColor hover:bg-primaryColor hover:text-whiteColor shadow-overview-button dark:bg-whiteColor-dark dark:text-blackColor-dark dark:hover:bg-primaryColor dark:hover:text-whiteColor flex items-center active">
                                            <i class="icofont-book-alt mr-2"></i> دوره
                                        </button>

                                    </div>

                                    <div class="tab-contents">
                                        <!-- curriculum -->
                                        <div>
                                            <ul class="accordion-container curriculum">
                                                <!-- accordion -->

                                                <li v-for="(sub,subindex) in course.lessons" :key="subindex"
                                                    @click="showhide(subindex)"
                                                    :class="'accordion mb-25px overflow-hidden '+(active[subindex]?'active':'') ">
                                                    <div
                                                        class="bg-whiteColor border border-borderColor dark:bg-whiteColor-dark dark:border-borderColor-dark rounded-t-md">
                                                        <!-- controller -->
                                                        <div>
                                                            <div
                                                                class="cursor-pointer accordion-controller flex justify-between items-center text-xl text-headingColor font-bold w-full px-5 py-18px dark:text-headingColor-dark font-hind leading-[20px]">
                                                                <div class="flex items-center">
                                                                    <span>{{ sub.title }}</span>
                                                                    <p
                                                                        class="text-xs text-headingColor dark:text-headingColor-dark px-10px py-0.5 ml-10px bg-borderColor dark:bg-borderColor-dark rounded-full">
                                                                        <!-- 02hr 35min -->
                                                                    </p>
                                                                </div>
                                                                <svg class="transition-all duration-500 rotate-0"
                                                                    width="20" xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 16 16" fill="#212529">
                                                                    <path fill-rule="evenodd"
                                                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z">
                                                                    </path>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <!-- content -->
                                                        <div
                                                            :class="'accordion-content transition-all duration-500 ' + (active[subindex] ? '' : 'h-0') ">
                                                            <div class="content-wrapper p-10px md:px-30px">
                                                                <ul>


                                                                    <li v-for="(lesson,lessonindex) in sub.list"
                                                                        :key="sub +'_'+lessonindex"
                                                                        class="py-4 flex items-center justify-between flex-wrap border-b border-borderColor dark:border-borderColor-dark">
                                                                        <div>
                                                                            <h4
                                                                                class="text-blackColor dark:text-blackColor-dark leading-1 font-light">
                                                                                <i
                                                                                    class="icofont-video-alt mr-10px"></i>
                                                                                <span class="font-medium">
                                                                                    فیلم :
                                                                                </span>
                                                                                {{ lesson.title }}
                                                                            </h4>
                                                                        </div>
                                                                        <div
                                                                            class="text-blackColor dark:text-blackColor-dark text-sm flex items-center">
                                                                            <p>
                                                                                {{ getTime(lesson.time) }}
                                                                                <i class="icofont-clock-time"></i>
                                                                            </p>
                                                                            <!-- <a href="lesson.html"
                                                                                class="bg-primaryColor text-whiteColor text-sm ml-5 rounded py-0.5">
                                                                                <p class="px-10px">
                                                                                    <i class="icofont-eye"></i> Preview
                                                                                </p>
                                                                            </a> -->
                                                                        </div>
                                                                    </li>




                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>

                                    </div>
                                </div>

                                <!-- tag and share  -->

                                <div class="flex justify-between items-center flex-wrap py-10 mb-10 border-y border-borderColor2 dark:border-borderColor2-dark gap-y-10px"
                                    data-aos="fade-up">
                                    <div>
                                        <ul class="flex flex-wrap gap-10px">
                                            <li>
                                                <p
                                                    class="text-lg md:text-size-22 leading-7 md:leading-30px text-blackColor dark:text-blackColor-dark font-bold">
                                                    Tag
                                                </p>
                                            </li>
                                            <li v-for="(tag,tagindex) in course.tags" :key="tagindex">
                                                <a href="blog-details.html"
                                                    class="px-2 py-5px md:px-3 md:py-9px text-contentColor text-size-11 md:text-xs font-medium uppercase border border-borderColor2 hover:text-whiteColor hover:bg-primaryColor hover:border-primaryColor dark:text-contentColor-dark dark:border-borderColor2-dark dark:hover:text-whiteColor dark:hover:bg-primaryColor dark:hover:border-primaryColor rounded">
                                                    {{ tag }}
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <!-- social -->
                                        <div>
                                            <ul class="flex gap-10px justify-center items-center">
                                                <li>
                                                    <p
                                                        class="text-lg md:text-size-22 leading-7 md:leading-30px text-blackColor dark:text-blackColor-dark font-bold">
                                                        Share
                                                    </p>
                                                </li>
                                                <li>
                                                    <a href="#"
                                                        class="h-35px w-35px leading-35px md:w-38px md:h-38px md:leading-38px text-size-11 md:text-xs text-center border border-borderColor2 text-contentColor hover:text-whiteColor hover:bg-primaryColor dark:text-contentColor-dark dark:hover:text-whiteColor dark:hover:bg-primaryColor dark:border-borderColor2-dark rounded"><i
                                                            class="icofont-twitter"></i></a>
                                                </li>
                                                <li>
                                                    <a href="#"
                                                        class="h-35px w-35px leading-35px md:w-38px md:h-38px md:leading-38px text-size-11 md:text-xs text-center border border-borderColor2 text-contentColor hover:text-whiteColor hover:bg-primaryColor dark:text-contentColor-dark dark:hover:text-whiteColor dark:hover:bg-primaryColor dark:border-borderColor2-dark rounded"><i
                                                            class="icofont-facebook"></i></a>
                                                </li>

                                                <li>
                                                    <a href="#"
                                                        class="h-35px w-35px leading-35px md:w-38px md:h-38px md:leading-38px text-size-11 md:text-xs text-center border border-borderColor2 text-contentColor hover:text-whiteColor hover:bg-primaryColor dark:text-contentColor-dark dark:hover:text-whiteColor dark:hover:bg-primaryColor dark:border-borderColor2-dark rounded"><i
                                                            class="icofont-instagram"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- course sidebar -->
                    <div class="lg:col-start-9 lg:col-span-4">
                        <div class="flex flex-col">
                            <!-- enroll section -->
                            <div class="py-33px px-25px shadow-event mb-30px bg-whiteColor dark:bg-whiteColor-dark rounded-md"
                                data-aos="fade-up">
                                <!-- meeting thumbnail -->
                                <div class="overflow-hidden relative mb-5">
                                    <img :src="course.image + 'img-min.jpg'" alt="" class="w-full">
                                    <div
                                        class="absolute top-0 right-0 left-0 bottom-0 flex items-center justify-center z-10">
                                        <div>
                                            <button :data-url="course.clip"
                                                class="lvideo relative w-15 h-15 md:h-20 md:w-20 lg:w-15 lg:h-15 2xl:h-70px 2xl:w-70px 3xl:h-20 3xl:w-20 bg-secondaryColor rounded-full flex items-center justify-center">
                                                <span
                                                    class="animate-buble absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 block w-[180px] h-[180px] border-secondaryColor rounded-full"></span><span
                                                    class="animate-buble2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 block w-[180px] h-[180px] border-secondaryColor rounded-full"></span>
                                                <img src="/assets/images/icon/video.png" alt="">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex justify-between mb-5">
                                    <div class="text-size-21 font-bold text-primaryColor font-inter leading-25px">
                                        <span class="ml-6"> رایگان</span>
                                    </div>
                                    <div>
                                       
                                    </div>
                                </div>
                                <div class="mb-5" data-aos="fade-up">
                                    <button type="submit"
                                        @click="startLesson"
                                        class="w-full text-size-15 text-whiteColor bg-primaryColor px-25px py-10px border mb-10px leading-1.8 border-primaryColor hover:text-primaryColor hover:bg-whiteColor inline-block rounded group dark:hover:text-whiteColor dark:hover:bg-whiteColor-dark">
                                        شروع دوره
                                    </button>
                                    <!-- <button type="submit"
                                        class="w-full text-size-15 text-whiteColor bg-secondaryColor px-25px py-10px mb-10px leading-1.8 border border-secondaryColor hover:text-secondaryColor hover:bg-whiteColor inline-block rounded group dark:hover:text-secondaryColor dark:hover:bg-whiteColor-dark">
                                        Buy Now
                                    </button> -->
 
                                </div>
                                <ul>
                                    <li
                                        class="flex items-center justify-between py-10px border-b border-borderColor dark:border-borderColor-dark">
                                        <p
                                            class="text-sm font-medium text-contentColor dark:text-contentColor-dark leading-1.8">
                                            مدرس:
                                        </p>
                                        <p
                                            class="text-xs text-contentColor dark:text-contentColor-dark px-10px py-6px bg-borderColor dark:bg-borderColor-dark rounded-full leading-13px">
                                            {{ course.instructor }}
                                        </p>
                                    </li>
                                     
                                    <li
                                        class="flex items-center justify-between py-10px border-b border-borderColor dark:border-borderColor-dark">
                                        <p
                                            class="text-sm font-medium text-contentColor dark:text-contentColor-dark leading-1.8">
                                            زمان دوره
                                        </p>
                                        <p
                                            class="text-xs text-contentColor dark:text-contentColor-dark px-10px py-6px bg-borderColor dark:bg-borderColor-dark rounded-full leading-13px">
                                            {{ course.strTime }}
                                        </p>
                                    </li> 
                                    
                                    <li
                                        class="flex items-center justify-between py-10px border-b border-borderColor dark:border-borderColor-dark">
                                        <p
                                            class="text-sm font-medium text-contentColor dark:text-contentColor-dark leading-1.8">
                                            سطح دوره
                                        </p>
                                        <p
                                            class="text-xs text-contentColor dark:text-contentColor-dark px-10px py-6px bg-borderColor dark:bg-borderColor-dark rounded-full leading-13px">
                                            {{ course.level }}
                                        </p>
                                    </li>
                                    <li
                                        class="flex items-center justify-between py-10px border-b border-borderColor dark:border-borderColor-dark">
                                        <p
                                            class="text-sm font-medium text-contentColor dark:text-contentColor-dark leading-1.8">
                                            زبان
                                        </p>
                                        <p
                                            class="text-xs text-contentColor dark:text-contentColor-dark px-10px py-6px bg-borderColor dark:bg-borderColor-dark rounded-full leading-13px">
                                            {{ course.lang }}
                                        </p>
                                    </li>
                                     
                                     
                                </ul> 
                            </div>
                            <!-- social area -->
                            <div class="p-5 md:p-30px lg:p-5 2xl:p-30px mb-30px border border-borderColor2 dark:border-borderColor2-dark"
                                data-aos="fade-up">
                                <h4
                                    class="text-size-22 text-blackColor dark:text-blackColor-dark font-bold pl-2 before:w-0.5 relative before:h-[21px] before:bg-primaryColor before:absolute before:bottom-[5px] before:left-0 leading-30px mb-25px">
                                    Follow Us
                                </h4>
                                <div>
                                    <ul class="flex gap-4 items-center">
                                        <li>
                                            <a href="#"
                                                class="w-38px h-38px leading-38px text-center text-blackColor2 bg-whitegrey2 hover:text-whiteColor hover:bg-primaryColor dark:bg-whitegrey2-dark dark:text-blackColor2-dark dark:hover:text-whiteColor dark:hover:bg-primaryColor rounded"><i
                                                    class="icofont-facebook"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"
                                                class="w-38px h-38px leading-38px text-center text-blackColor2 bg-whitegrey2 hover:text-whiteColor hover:bg-primaryColor dark:bg-whitegrey2-dark dark:text-blackColor2-dark dark:hover:text-whiteColor dark:hover:bg-primaryColor rounded"><i
                                                    class="icofont-youtube-play"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"
                                                class="w-38px h-38px leading-38px text-center text-blackColor2 bg-whitegrey2 hover:text-whiteColor hover:bg-primaryColor dark:bg-whitegrey2-dark dark:text-blackColor2-dark dark:hover:text-whiteColor dark:hover:bg-primaryColor rounded"><i
                                                    class="icofont-instagram"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"
                                                class="w-38px h-38px leading-38px text-center text-blackColor2 bg-whitegrey2 hover:text-whiteColor hover:bg-primaryColor dark:bg-whitegrey2-dark dark:text-blackColor2-dark dark:hover:text-whiteColor dark:hover:bg-primaryColor rounded"><i
                                                    class="icofont-twitter"></i></a>
                                        </li>
                                        <li>
                                            <a href="#"
                                                class="w-38px h-38px leading-38px text-center text-blackColor2 bg-whitegrey2 hover:text-whiteColor hover:bg-primaryColor dark:bg-whitegrey2-dark dark:text-blackColor2-dark dark:hover:text-whiteColor dark:hover:bg-primaryColor rounded"><i
                                                    class="icofont-instagram"></i></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>


</template>

<script lang="ts"> 
import CourseModel from '@/api/models/CourseModel';
import PublicService from '@/api/services/PublicService';
import Tools from '@/api/Tools';
import { Component, Vue } from 'vue-property-decorator'; 

@Component({
    components: { 
    },
})
export default class CourseDetail extends Vue {
    counter: number = 0;
    loading:boolean = true;
    course: CourseModel = new CourseModel(); 
    active:any={}
    mounted()
    {
        this.init()
    }
    getTime(time:number)
    {
        return Tools.getTimerStr(time)
    }
    async init()
    {
        let id = parseInt(this.$route.params['id']);
        this.course = await PublicService.getCourseById(id)
        for (let i = 0; i < this.course.lessons.length;i++ )
        {
            this.active[i] = false;
        }
        this.active[0] = true; 
        this.loading = false;
    }
    startLesson()
    {
        window.location.href ='/lesson/'+ this.course._id+'/0/0'
        //this.$router.push()
    }
    showhide(index:number)
    {
        for (let a in this.active)
        {
            this.active[a]=false
        }
        this.active[index] = true ;
        this.counter++;
    }
}
</script>