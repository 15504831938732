import Config from "../Config";
import Tools from "../Tools";
import CourseModel from "../models/CourseModel";
import Instructor from "../models/Instructor";
import BaseServices from "./BaseService";

export default class PublicService {
    static async getCourses( ): Promise<CourseModel[]> {
        let data: any = await BaseServices.post(Config.url + 'getCourses', {});
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>',data);
        
        return Tools.toArray(data.value, CourseModel);
    }
    static async getCourseById( id:number ): Promise<CourseModel > {
        let data: any = await BaseServices.post(Config.url + 'getCourseById', { id });
        return new CourseModel(data);
    }
    static async getCourseByGroup(category:number ): Promise<CourseModel[]> {
        let data: any = await BaseServices.post(Config.url + 'getCourseByGroup', {id:category});  
        return Tools.toArray(data.value, CourseModel);
    }
    static async getCourseByInstructor(instructor:number ): Promise<CourseModel[]> {
        let data: any = await BaseServices.post(Config.url + 'getCourseByInstructor', {id:instructor});  
        return Tools.toArray(data.value, CourseModel);
    }
    static async getInstructor( id:number): Promise<Instructor> {
        let data: any = await BaseServices.post(Config.url + 'getInstructor', {id}); 
        
        return new Instructor(data);
    }
}