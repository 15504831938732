<template>
    <main class="bg-transparent">

        <!--blog details section -->
        <section>
            <div class="container py-100px">
                <div class="w-full lg:max-w-770px lg:mx-auto text-center">
                    <img src="/assets/images/icon/error__1.png" alt="" class="w-full mb-60px">
                    <h3
                        class="text-size-35 md:text-size-40 lg:text-size-50 leading-10 md:leading-14.5 lg:leading-20 text-blackColor dark:text-blackColor-dark font-bold">
                        صفحه مورد نظر پیدا نشد
                    </h3>
                    <p class="text-lg text-contentColor dark:text-contentColor-dark leading-22px">
                        متاسفانه صفحه ای که باز کرده اید وجود ندارد. در صورت اطمینان از مسیر خود خرابی را به اپراتور
                        اطلاع بدهید
                    </p>
                    <div class="mt-50px text-center">
                        <a href="/"
                            class="text-size-15 font-semibold text-whiteColor bg-primaryColor border border-primaryColor px-43px py-4 hover:text-primaryColor hover:bg-whiteColor rounded inline-block dark:hover:bg-whiteColor-dark dark:hover:text-whiteColor">
                            <i class="icofont-simple-left"></i> بازگشت به صفحه نخست
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </main>


</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class Error404 extends Vue {

}
</script>