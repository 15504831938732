<template>
  <main class="bg-transparent">
     
      <!-- courses section -->
      <div v-if="showCourses">
        <div class="container tab py-10 md:py-50px lg:py-60px 2xl:py-100px">
        
          <!-- courses -->
          <div class="tab-contents" style="direction: rtl;">
            <!-- grid ordered cards -->
            <div class="transition-all duration-300">
              <div
                class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-30px"
              >
                <!-- card 1 -->

                
                <CourseCard1 v-for="(itm,index) in courses" :key="index" :course="itm" />
                
 
 
 
              </div>
            </div>
 
          </div>
          <!-- pagination -->
          <div v-if="false">
            <ul
              class="flex items-center justify-center gap-15px mt-60px mb-30px"
            >
              <li>
                <a
                  href="#"
                  class="w-10 h-10 leading-10 md:w-50px md:h-50px md:leading-50px text-center text-blackColor2 hover:text-whiteColor bg-whitegrey1 hover:bg-primaryColor dark:text-blackColor2-dark dark:hover:text-whiteColor dark:bg-whitegrey1-dark dark:hover:bg-primaryColor cursor-not-allowed"
                  ><i class="icofont-double-left"></i
                ></a>
              </li>
              <li>
                <a
                  href="#"
                  class="w-10 h-10 leading-10 md:w-50px md:h-50px md:leading-50px text-center text-whiteColor hover:text-whiteColor bg-primaryColor hover:bg-primaryColor dark:text-blackColor2-dark dark:hover:text-whiteColor dark:bg-primaryColor dark:hover:bg-primaryColor"
                  >1</a
                >
              </li>
              <li>
                <a
                  href="#"
                  class="w-10 h-10 leading-10 md:w-50px md:h-50px md:leading-50px text-center text-blackColor2 hover:text-whiteColor bg-whitegrey1 hover:bg-primaryColor dark:text-blackColor2-dark dark:hover:text-whiteColor dark:bg-whitegrey1-dark dark:hover:bg-primaryColor"
                  >2</a
                >
              </li>
              <li>
                <a
                  href="#"
                  class="w-10 h-10 leading-10 md:w-50px md:h-50px md:leading-50px text-center text-blackColor2 hover:text-whiteColor bg-whitegrey1 hover:bg-primaryColor dark:text-blackColor2-dark dark:hover:text-whiteColor dark:bg-whitegrey1-dark dark:hover:bg-primaryColor"
                  >3</a
                >
              </li>
              <li>
                <a
                  href="#"
                  class="w-10 h-10 leading-10 md:w-50px md:h-50px md:leading-50px text-center text-blackColor2 hover:text-whiteColor bg-whitegrey1 hover:bg-primaryColor dark:text-blackColor2-dark dark:hover:text-whiteColor dark:bg-whitegrey1-dark dark:hover:bg-primaryColor"
                  ><i class="icofont-double-right"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </main>
   
</template>

<script lang="ts">
import CourseModel from '@/api/models/CourseModel';
import PublicService from '@/api/services/PublicService';
import { Component, Vue, Watch } from 'vue-property-decorator';
import CourseCard1 from './HomeComponents/CourseCrad1.vue';

@Component({
    components: {
        CourseCard1
    },
})
export default class CoursesList extends Vue {

    
    showCourses: boolean = false;
    courses: CourseModel[] = [];
    mounted()
    {
        setTimeout(() => {
            this.init()
            this.showCourses=true
        }, 100)
    }
    async init()
    {
        let id = parseInt(this.$route.params['id']);
        this.courses = await PublicService.getCourseByGroup(id)
    } 
    @Watch('$route')
    reatechanged(data:any)
    {  
        try { 
            this.init()
        } catch (exp) { } 
 
    }
}
</script>