<template>
     
     <div class="group">
                  <div class="tab-content-wrapper" data-aos="fade-up">
                    <div
                      class="p-15px bg-whiteColor shadow-brand dark:bg-darkdeep3-dark dark:shadow-brand-dark"
                    >
                      <!-- card image -->
                      <div class="relative mb-4 overflow-hidden">
                        <a :href="'/course/'+course._id" class="w-full">
                          <img
                             :src=" course.image+(isLargeImage ?'img.jpg':'img-min.jpg')"
                            alt=""
                            class="w-full transition-all duration-300 group-hover:scale-110"
                          >
                        </a>
                        <div
                          class="absolute left-0 top-1 flex justify-between w-full items-center px-2"
                        >
                          <div>
                            <p
                              class="text-xs text-whiteColor px-4 py-[3px] bg-secondaryColor rounded font-semibold"
                            >
                            {{ course.category }}
                            </p>
                          </div>
                          <!-- <a
                            class="text-white bg-black bg-opacity-15 rounded hover:bg-primaryColor"
                            href="#"
                            ><i
                              class="icofont-heart-alt text-base py-1 px-2"
                            ></i
                          ></a> -->
                        </div>
                      </div>
                      <!-- card content -->
                      <div>
                        <div class="grid grid-cols-2 mb-15px">
                          <div class="flex items-center">
                            <div>
                              <i
                                class="icofont-book-alt pr-5px text-primaryColor text-lg"
                              ></i>
                            </div>
                            <div>
                              <span
                                class="text-sm text-black dark:text-blackColor-dark"
                                >{{ course.lessonsCount }}
                                درس</span
                              >
                            </div>
                          </div>
                          <div class="flex items-center">
                            <div>
                              <i
                                class="icofont-clock-time pr-5px text-primaryColor text-lg"
                              ></i>
                            </div>
                            <div>
                              <span
                                class="text-sm text-black dark:text-blackColor-dark"
                                >{{ course.strTime }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <a
                          href="course-details.html"
                          class="text-lg font-semibold text-blackColor mb-10px font-hind dark:text-blackColor-dark hover:text-primaryColor dark:hover:text-primaryColor"
                        >
                          
                        {{ course.title }}
                        </a>
                        <!-- price -->
                        <div
                          class="text-lg font-semibold text-primaryColor font-inter mb-4"
                        >
                          <!-- $32.00
                          <del class="text-sm text-lightGrey4 font-semibold"
                            >/ $67.00</del
                          > -->
                          <span class="ml-6"
                            > Free</span
                          >
                        </div>
                        <!-- author and rating-->
                        <div
                          class="grid grid-cols-1 md:grid-cols-2 pt-15px border-t border-borderColor"
                        >
                          <div>
                            <a
                              :href="'/instructor/'+course.instructorId"
                              class="text-base font-bold font-hind flex items-center hover:text-primaryColor dark:text-blackColor-dark dark:hover:text-primaryColor"
                              ><img
                                class="w-[30px] h-[30px] rounded-full mr-15px"
                                 :src="'https://storage.namag.ir/instructor/'+course.instructorId+'.jpg' "
                                alt=""
                              >
                              <span class="flex">{{ course.instructor }}</span>
                            </a>
                          </div>
                          <Star :rate="course.rate" :count="course.rateCount" />
                            
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
</template>
<script lang="ts"> 
import CourseModel from '@/api/models/CourseModel';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Star from './Star.vue';

@Component({
    components: {
        Star
    },
})
export default class CourseCard1 extends Vue {
    @Prop()
    isLargeImage:boolean;
    @Prop()
    course: CourseModel = new CourseModel(); 
    mounted() { 
    }
}
</script>