<template>
    <main class="bg-transparent">
        <!-- lesson section -->
        <section v-if="!loading">
            <div style="display: none;">
                {{ counter }}

            </div>
            <div class="container-fluid-2 pt-50px pb-100px" style="direction: rtl;">
                <div class="grid grid-cols-1 xl:grid-cols-12 gap-30px">
                    <!-- lesson left -->
                    <div class="xl:col-start-1 xl:col-span-4" data-aos="fade-up">
                        <!-- curriculum -->
                        <ul class="accordion-container curriculum">
                            <!-- accordion -->
                            <li v-for="(lesson,lessonindex) in course.lessons" :key="lessonindex"
                                :class="'accordion mb-25px overflow-hidden ' + (active[lessonindex] ? 'active' : '') "
                                @click="showhide(lessonindex)">
                                <div
                                    class="bg-whiteColor border border-borderColor dark:bg-whiteColor-dark dark:border-borderColor-dark rounded-t-md">
                                    <!-- controller -->
                                    <div>
                                        <button
                                            class="accordion-controller flex justify-between items-center text-xl text-headingColor font-bold w-full px-5 py-18px dark:text-headingColor-dark font-hind leading-[20px]">
                                            <span>{{ lesson.title }} </span>

                                            <svg class="transition-all duration-500 rotate-0" width="20"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#212529">
                                                <path fill-rule="evenodd"
                                                    d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z">
                                                </path>
                                            </svg>
                                        </button>
                                    </div>
                                    <!-- content -->
                                    <div
                                        :class="'accordion-content transition-all duration-500 ' + (active[lessonindex] ? '' : 'h-0') ">
                                        <div class="content-wrapper p-10px md:px-30px">
                                            <ul>
                                                <li v-for="(sub,subIndex) in lesson.list" style="cursor: pointer;"
                                                    :key="subIndex + '_' + lessonindex"
                                                    @click="reloadVideo(lessonindex, subIndex)"
                                                    class="py-4 flex items-center justify-between flex-wrap border-b border-borderColor dark:border-borderColor-dark">
                                                    <div>
                                                        <h4
                                                            class="text-blackColor dark:text-blackColor-dark leading-1 font-light">
                                                            <i class="icofont-video-alt mr-10px"></i>
                                                            <span class="font-medium">
                                                                فیلم :
                                                            </span>
                                                            <a
                                                                class="font-medium text-contentColor dark:text-contentColor-dark hover:text-primaryColor dark:hover;text-primaryColor">

                                                                <span>
                                                                    {{ sub.title }}
                                                                </span>
                                                            </a>
                                                        </h4>
                                                    </div>
                                                    <div
                                                        class="text-blackColor dark:text-blackColor-dark text-sm flex items-center">

                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>



                        </ul>
                    </div>
                    <!-- lesson right -->
                    <div class="xl:col-start-5 xl:col-span-8 relative" data-aos="fade-up">
                        <div>
                            <div
                                class="absolute top-0 left-0 w-full flex justify-between items-center px-5 py-10px bg-primaryColor leading-1.2 text-whiteColor">
                                <h3 class="sm:text-size-22 font-bold">
                                    {{ selectedLesson.title }}
                                </h3>
                                <a :href="'/course/'+course._id" class="">Close</a>
                            </div>

                            <div class="aspect-[16/9]" v-if="!loadingVideo && true">
                                <iframe :src="selectedLesson.address" allowfullscreen="" allow="autoplay"
                                    class="w-full h-full"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>

</template>

<script lang="ts">
import CourseModel, { SubLessonModel } from '@/api/models/CourseModel';
import PublicService from '@/api/services/PublicService';
import Tools from '@/api/Tools'; 
import { Component, Vue ,Watch} from 'vue-property-decorator';

@Component({
    components: {},
})
export default class Lesson extends Vue {

    counter: number = 0;
    loading: boolean = true;
    course: CourseModel = new CourseModel();
    active: any = {}
    selectedLesson: SubLessonModel;
    loadingVideo: boolean = false;
    lessonIndex = 0;
    subIndex = 0;
    mounted() {
        this.init()
    }
    getTime(time: number) {
        return Tools.getTimerStr(time)
    }
    async init() {
        let id = parseInt(this.$route.params['id']);
        this.course = await PublicService.getCourseById(id)
        for (let i = 0; i < this.course.lessons.length; i++) {
            this.active[i] = false;
        }
        this.active[0] = true;
        this.loading = false;
        let lessonIndex = 0;
        let subIndex = 0;
        try {
            lessonIndex = parseInt(this.$route.params['lesson']);
            subIndex = parseInt(this.$route.params['sub']);
            this.loadVideo(lessonIndex,subIndex)
        } catch (exp) { } 
        this.lessonIndex = lessonIndex;
        this.subIndex = subIndex;
    }
    showhide(index: number) {
        for (let a in this.active) {
            this.active[a] = false
        }
        this.active[index] = true;
        this.counter++;
    }
    loadVideo(lessonIndex: number, subIndex:number)
    { 
        this.loadingVideo = true;
        setTimeout(() => {
            this.loadingVideo = false;
        },300)
        this.selectedLesson = this.course.lessons[lessonIndex].list[subIndex];
        
    }
    reloadVideo(lessonIndex: number, subIndex: number)
    {
        if (lessonIndex != this.lessonIndex || this.subIndex != subIndex)
        {
            this.$router.push('/lesson/' + this.course._id + '/' + lessonIndex + '/' + subIndex)

        }
        //this.loadVideo(lessonIndex,subIndex)
    }
    @Watch('$route')
    reatechanged(data:any)
    { 
        let lessonIndex = 0;
        let subIndex = 0;
        try {
            lessonIndex = parseInt(this.$route.params['lesson']);
            subIndex = parseInt(this.$route.params['sub']);
            this.loadVideo(lessonIndex, subIndex)
        } catch (exp) { } 

        this.lessonIndex = lessonIndex;
        this.subIndex = subIndex;
    }
}
</script>